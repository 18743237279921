.tabs {
	&.tabs-transparent {
		background-color: transparent;

		.tab a{
			color: $tabs-text-color;
		}
		.tab.disabled a,
		.tab.disabled a:hover {
			color: $disableColor;
			cursor: default;
		}
		.tab a:hover,
		.tab a.active {
			background: none;
			color: $tabs-text-color;
		}
		.indicator {
			background-color: $tabs-underline-color;
		}
	}
	height: 48px;
	background-color: $tabs-bg-color;
	.tab {
		a {
			&:hover,
			&.active {
				background-color: transparent;
				color: $tabs-text-color;
			}
			color: $tabs-text-color;
			font-size: 14px;
		}
		&.disabled a,
		&.disabled a:hover {
			color: $disableColor;
			cursor: default;
		}
	}
	.indicator {
		position: absolute;
		bottom: 0;
		height: 2px;
		background-color: $tabs-underline-color;
		will-change: left, right;
	}
}