
a {
	text-decoration: none;
}

$lineHeight: 24px;

html {
	line-height: $lineHeight;

	@media only screen and (min-width: 0) {
		font-size: (14px * 1.08);
	}

	@media only screen and (min-width: $medium-screen) {
		font-size: (14.5px * 1.08);
	}

	@media only screen and (min-width: $large-screen) {
		font-size: (15px * 1.08);
	}

	font-family: "Source Sans Pro", sans-serif;
	font-weight: normal;
	color: $off-black;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
	line-height: 1.1;
}

// Header Styles
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	font-weight: inherit;
}

h1, h2, h3, h4, .hatch-display-4, .hatch-display-3, .hatch-display-2, .hatch-display-1, .hatch-headline, .hatch-title, .promo-caption, .card-title, nav .nav-title{
	color: $globalTextColorLight;
	.typography-label{
		color: $globalTextColorLight;
	}
	&.header{
		color: $globalTextColorLight;
	}
}

h1 {
	font-size: $hatch-display-3;
	line-height: 110%;
	margin: ($hatch-display-3 / 2) 0 ($hatch-display-3 / 2.5) 0;
}

h2 {
	font-size: $hatch-display-1;
	line-height: 110%;
	margin: ($hatch-display-1 / 2) 0 ($hatch-display-1 / 2.5) 0;
}
h3 {
	font-size: $hatch-headline;
	line-height: 110%;
	margin: ($hatch-headline / 2) 0 ($hatch-headline / 2.5) 0;
}
h4 {
	font-weight: 600;
	font-size: $hatch-title;
	line-height: 110%;
	margin: ($hatch-title / 2) 0 ($hatch-title / 2.5) 0;
}
h5 {
	font-size: $hatch-subheading;
	line-height: 110%;
	margin: ($hatch-subheading / 2) 0 ($hatch-subheading / 2.5) 0;
}
h6 {
	font-weight: 600;
	font-size: $hatch-body-2;
	line-height: $lineHeight;
	margin: ($hatch-body-2 / 2) 0 ($hatch-body-2 / 2.5) 0;
}

.hatch-display-4{
	font-weight: 300;
	font-size: $hatch-display-4;
	line-height: 110%;
	margin: ($hatch-display-4 / 2) 0 ($hatch-display-4 / 2.5) 0;
}
.hatch-display-3{
	font-size: $hatch-display-3;
	line-height: 110%;
	margin: ($hatch-display-3 / 2) 0 ($hatch-display-3 / 2.5) 0;
}
.hatch-display-2{
	font-size: $hatch-display-2;
	line-height: 110%;
	margin: ($hatch-display-2 / 2) 0 ($hatch-display-2 / 2.5) 0;
}
.hatch-display-1{
	font-size: $hatch-display-1;
	line-height: 110%;
	margin: ($hatch-display-1 / 2) 0 ($hatch-display-1 / 2.5) 0;
}
.hatch-headline{
	font-size: $hatch-headline;
	line-height: 110%;
	margin: ($hatch-headline / 2) 0 ($hatch-headline / 2.5) 0;
}
.hatch-title{
	font-weight: 600;
	font-size: $hatch-title;
	line-height: 110%;
	margin: ($hatch-title / 2) 0 ($hatch-title / 2.5) 0;
}
.hatch-subheading{
	font-size: $hatch-subheading;
	line-height: 110%;
	margin: ($hatch-subheading / 2) 0 ($hatch-subheading / 2.5) 0;
}
.hatch-body-2{
	font-weight: 600;
	font-size: $hatch-body-2;
	line-height: $lineHeight;
	margin: ($hatch-body-2 / 2) 0 ($hatch-body-2 / 2.5) 0;
}

// Text Styles

.hatch-caption{
	font-size: $hatch-caption !important;
}
em {
	font-style: italic;
}

strong {
	font-weight: 600;
}

small {
	font-size: 75%;
}

.light {
	font-weight: 300;
}

.thin {
	font-weight: 200;
}