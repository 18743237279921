.spinner-layer {
	border-color: $spinner-default-color;
}

.spinner-blue,
.spinner-blue-only {
	border-color: color("hatch-grape", "base");
}

.spinner-red,
.spinner-red-only {
	border-color: $hatch-orange;
}

.spinner-yellow,
.spinner-yellow-only {
	border-color: color("hatch-yellow", "base");
}

.spinner-green,
.spinner-green-only {
	border-color: color("hatch-goblin", "base");
}