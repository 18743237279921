// shared styles
.btn,
.btn-flat {
	border: $button-border;
	border-radius: $button-radius;
	height: $button-height;
	line-height: $button-height;
	padding: $button-padding;
}
// Disabled shared style
.btn.disabled,
.btn-floating.disabled,
.btn-large.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-flat:disabled,
.btn[disabled],
.btn-floating[disabled],
.btn-large[disabled],
.btn-flat[disabled] {
	background-color: $button-disabled-background !important;
	color: $button-disabled-color !important;
	&:hover {
		background-color: $button-disabled-background !important;
		color: $button-disabled-color !important;
	}
}
// Shared icon styles
.btn,
.btn-floating,
.btn-large,
.btn-flat {
	i {
		font-size: $button-font-size;
	}
}
// Shared focus button style
.btn,
.btn-floating {
	&:focus {
		background-color: darken($button-raised-background, 10%);
	}
}

// Raised Button
.btn {
	color: $button-raised-color;
	background-color: $button-raised-background;
	text-align: center;
	letter-spacing: .5px;
	@extend .z-depth-1;
	transition: .2s ease-out;
	cursor: pointer;
	&:hover {
		background-color: $button-raised-background-hover;
		@extend .z-depth-1-half;
	}
	i{
		&.material-icons, &.library-font-awesome-icons{
			color: #fff;
		}
	}
}
.btn-icon{
	box-shadow: none;
	background-color: transparent;
	color: $iconColor;
	cursor: pointer;
	transition: background-color .2s;
	border-radius: 500rem;
	height: 36px;
	line-height: 36px;
	font-size: 24px;
	width: 36px;
	text-align: center;
	display: inline-block;
	margin: 0 8px;
	.library-font-awesome-icons{
		line-height: 36px;
	}
	&:focus,
	&:active {
		background-color: transparent;
	}
	&:focus,
	&:hover {
		color: $globalTextColor;
		.library-font-awesome-icons{
			color: $globalTextColor;
		}
	}
	&.disabled {
		color: $disableColor !important;
		cursor: default;
	}
}
// Floating button
.btn-floating {
	&:hover {
		background-color: $button-floating-background-hover;
		@extend .z-depth-1-half;
	}
	&:before {
		border-radius: 0;
	}
	&.btn-large {
		width: $button-floating-large-size;
		height: $button-floating-large-size;
		i {
			line-height: $button-floating-large-size;
		}
	}
	&.halfway-fab {
		&.left {
			right: auto;
			left: 24px;
		}
		position: absolute;
		right: 24px;
		bottom: 0;
		transform: translateY(50%);
	}
	display: inline-block;
	color: $button-floating-color;
	position: relative;
	overflow: hidden;
	z-index: 1;
	width: $button-floating-size;
	height: $button-floating-size;
	line-height: $button-floating-size;
	padding: 0;
	background-color: $button-floating-background;
	border-radius: $button-floating-radius;
	@extend .z-depth-1;
	transition: .3s;
	cursor: pointer;
	vertical-align: middle;
	i {
		width: inherit;
		display: inline-block;
		text-align: center;
		color: $button-floating-color;
		font-size: $button-large-icon-font-size;
		line-height: $button-floating-size;
	}
}
// button fix
button.btn-floating {
	border: $button-border;
}
// Fixed Action Button
.fixed-action-btn {
	&.active {
		ul {
			visibility: visible;
		}
	}
	&.horizontal {
		padding: 0 0 0 15px;
		ul {
			text-align: right;
			right: 64px;
			top: 50%;
			transform: translateY(-50%);
			height: 100%;
			left: auto;
			width: 500px; /*width 100% only goes to width of button container */
			li {
				display: inline-block;
				margin: 15px 15px 0 0;
			}
		}
	}
	&.toolbar {
		&.active {
			& > a i {
				opacity: 0;
			}
		}
		padding: 0;
		height: $button-floating-large-size;
		ul {
			display: flex;
			top: 0;
			bottom: 0;
			li {
				flex: 1;
				display: inline-block;
				margin: 0;
				height: 100%;
				transition: none;
				a {
					display: block;
					overflow: hidden;
					position: relative;
					width: 100%;
					height: 100%;
					background-color: transparent;
					box-shadow: none;
					color: #fff;
					line-height: $button-floating-large-size;
					z-index: 1;
					i {
						line-height: inherit;
					}
				}
			}
		}
	}
	position: fixed;
	right: 23px;
	bottom: 23px;
	padding-top: 15px;
	margin-bottom: 0;
	z-index: 998;
	ul {
		left: 0;
		right: 0;
		text-align: center;
		position: absolute;
		bottom: 64px;
		margin: 0;
		visibility: hidden;
		li {
			margin-bottom: 15px;
		}
		a.btn-floating {
			opacity: 0;
		}
	}
	.fab-backdrop {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: $button-floating-size;
		height: $button-floating-size;
		background-color: $button-floating-background;
		border-radius: $button-floating-radius;
		transform: scale(0);
	}
}
// Flat button
.btn-flat {
	box-shadow: none;
	background-color: transparent;
	color: $button-flat-color;
	cursor: pointer;
	transition: background-color .2s;
	&:focus,
	&:active {
		background-color: transparent;
	}
	&:focus,
	&:hover {
		background-color: rgba(0, 0, 0, .1);
		box-shadow: none;
	}
	&:active {
		background-color: rgba(0, 0, 0, .2);
	}
	&.disabled {
		background-color: transparent !important;
		color: $button-flat-disabled-color !important;
		cursor: default;
	}
}
// Large button
.btn-large {
	@extend .btn;
	height: $button-large-height;
	line-height: $button-large-height;
	i {
		font-size: $button-large-icon-font-size;
	}
}
