body {
	color: $globalTextColor;
}
p{
	color: $globalTextColor;
}
a {
	color: $link-color;
}
// Z-levels
.z-depth-0 {
	box-shadow: none !important;
}
.z-depth-1 {
	//box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.z-depth-1-half {
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}
.z-depth-2 {
	//box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08), 0 1px 10px 0 rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-3 {
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-4 {
	box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.z-depth-5 {
	box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}

.hoverable {
	transition: box-shadow .25s;
	box-shadow: 0;
}

.hoverable:hover {
	transition: box-shadow .25s;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.divider {
	background-color: $globalBorderColor;
}
blockquote {
	border-left: 5px solid $primary-color;
}
.pagination {
	li {
		a {
			color: color("hatch-blue", "lighten-2");
		}
		&.active a {
			color: #fff;
		}
		&.active {
			background-color: color("hatch-blue", "lighten-2");
		}
		&.disabled a {
			color: $disableColor;
			.material-icons, .library-font-awesome-icons{
				color: $disableColor;
			}
		}
	}
}
// Breadcrumbs +
.breadcrumb {
	color: $globalTextColorLight;
	&:before {
		color: $globalTextColorLight;
	}
	&:last-child {
		color: $globalTextColor;
	}
}
// Footer +
footer.page-footer {
	padding-top: 20px;
	background-color: $footer-bg-color;

	.footer-copyright {
		overflow: hidden;
		height: 50px;
		line-height: 50px;
		color: rgba(255, 255, 255, .8);
		background-color: rgba(51, 51, 51, .08);
		@extend .light;
	}
}
// Tables +
table {
	&.bordered > thead > tr,
	&.bordered > tbody > tr {
		border-bottom: 1px solid $table-border-color;
	}
	&.striped > tbody {
		> tr:nth-child(odd) {
			background-color: $table-striped-color;
		}
	}

	&.highlight > tbody > tr {
		transition: background-color .25s ease;
		&:hover {
			background-color: $table-striped-color;
		}
	}
}
thead {
	border-bottom: 1px solid $table-border-color;
}
td, th {
	padding: 12px 32px 11px;
	border-radius: 0;
	&.right-align{
		text-align: right;
	}
}
td{
	font-size: 15px;
}
th{
	font-weight: 600;
	color: $globalTextColorLight;
	font-size: 14px;
}
// Responsive Table
@media #{$medium-and-down} {
	table.responsive-table {
		thead {
			border-right: 1px solid $table-border-color;
		}
		&.bordered {
			tbody tr {
				border-right: 1px solid $table-border-color;
			}
		}

	}

}
// Collections+
.collection {
	margin: $element-top-margin 0 $element-bottom-margin 0;
	border: 1px solid transparent;
	border-radius: 0;
	.collection-item {
		background-color: $collection-bg-color;
		line-height: 1.5rem;
		padding: 12px 20px;
		margin: 0;
		border-bottom: 1px solid $collection-border-color;
		// Avatar Collection
		&.active {
			background-color: $collection-active-bg-color;
			color: $collection-active-color;
			.secondary-content {
				color: #fff;
			}
			&:hover{
				background: $collection-active-bg-color;
			}
		}
	}
	a.collection-item {
		color: $collection-link-color;
		&:not(.active) {
			&:hover {
				background-color: $collection-hover-bg-color;
			}
		}
	}
	&.with-header {
		.collection-header {
			background-color: $collection-bg-color;
			border-bottom: 1px solid $collection-border-color;
			padding: 10px 20px;
		}
	}
}
// Made less specific to allow easier overriding
.secondary-content {
	color: $secondary-color;
}
// Badges
span.badge {
	min-width: 3rem;
	padding: 0 6px;
	margin-left: 14px;
	text-align: center;
	font-size: 1rem;
	line-height: inherit;
	color: $globalTextColor;
	float: right;
	box-sizing: border-box;
	&.new {
		font-weight: 300;
		font-size: 0.8rem;
		color: #fff;
		background-color: $badge-bg-color;
		border-radius: 2px;
	}
	&.new:after {
		content: " new";
	}
	&[data-badge-caption]::after {
		content: " " attr(data-badge-caption);
	}
}
nav ul a span.badge {
	display: inline-block;
	float: none;
	margin-left: 4px;
	line-height: 22px;
	height: 22px;
	&.new{
		font-weight: 400;
	}
}
.side-nav span.badge.new,
.collapsible span.badge.new {
	&::before {
		content: '';
		position: absolute;
		top: 10px;
		right: 0;
		bottom: 10px;
		left: 0;
		background-color: $badge-bg-color;
		border-radius: 2px;
		z-index: -1;
	}
	position: relative;
	background-color: transparent;
}

// Progress Bar
.progress {
	height: 4px;
	background-color: color("hatch-blue", "lighten-4");
	border-radius: 2px;
	margin: $element-top-margin 0 $element-bottom-margin 0;
	.determinate {
		background-color: $progress-bar-color;
	}
	.indeterminate {
		background-color: $progress-bar-color;
	}
}
