@font-face {
	font-family: "Font Awesome Icons";
	src:url('#{$hachIcons}font-awesome-icons.eot');
	src:url('#{$hachIcons}font-awesome-icons.eot?#iefix') format('embedded-opentype'),
	url('#{$hachIcons}font-awesome-icons.woff') format('woff'),
	url('#{$hachIcons}font-awesome-icons.ttf') format('truetype'),
	url('#{$hachIcons}font-awesome-icons.svg#untitled-font-1') format('svg');
	font-weight: normal;
	font-style: normal;
}

.library-font-awesome-icons{
	font-family: "Font Awesome Icons" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $iconColor;
}
.material-icons{
	color: $iconColor;
}


.icon-500px:before { content: "\e1eb"; }
.icon-adjust:before { content: "\61"; }
.icon-adn:before { content: "\63"; }
.icon-align-center:before { content: "\64"; }
.icon-align-justify:before { content: "\65"; }
.icon-align-left:before { content: "\66"; }
.icon-align-right:before { content: "\67"; }
.icon-amazon:before { content: "\68"; }
.icon-ambulance:before { content: "\69"; }
.icon-anchor:before { content: "\6a"; }
.icon-android:before { content: "\6b"; }
.icon-angellist:before { content: "\6c"; }
.icon-angle-double-down:before { content: "\6d"; }
.icon-angle-double-left:before { content: "\6e"; }
.icon-angle-double-right:before { content: "\6f"; }
.icon-angle-double-up:before { content: "\70"; }
.icon-angle-down:before { content: "\71"; }
.icon-angle-left:before { content: "\72"; }
.icon-angle-right:before { content: "\73"; }
.icon-angle-up:before { content: "\74"; }
.icon-apple:before { content: "\75"; }
.icon-archive:before { content: "\76"; }
.icon-area-chart:before { content: "\77"; }
.icon-arrow-circle-down:before { content: "\78"; }
.icon-arrow-circle-left:before { content: "\79"; }
.icon-arrow-circle-o-down:before { content: "\7a"; }
.icon-arrow-circle-o-left:before { content: "\41"; }
.icon-arrow-circle-o-right:before { content: "\42"; }
.icon-arrow-circle-o-up:before { content: "\43"; }
.icon-arrow-circle-right:before { content: "\44"; }
.icon-arrow-circle-up:before { content: "\45"; }
.icon-arrow-down:before { content: "\46"; }
.icon-arrow-left:before { content: "\47"; }
.icon-arrow-right:before { content: "\48"; }
.icon-arrow-up:before { content: "\49"; }
.icon-arrows:before { content: "\4a"; }
.icon-arrows-alt:before { content: "\4b"; }
.icon-arrows-h:before { content: "\4c"; }
.icon-arrows-v:before { content: "\4d"; }
.icon-asterisk:before { content: "\4e"; }
.icon-at:before { content: "\4f"; }
.icon-backward:before { content: "\50"; }
.icon-balance-scale:before { content: "\51"; }
.icon-ban:before { content: "\52"; }
.icon-bar-chart:before { content: "\53"; }
.icon-barcode:before { content: "\54"; }
.icon-bars:before { content: "\55"; }
.icon-battery-empty:before { content: "\56"; }
.icon-battery-full:before { content: "\57"; }
.icon-battery-half:before { content: "\58"; }
.icon-battery-quarter:before { content: "\59"; }
.icon-battery-three-quarters:before { content: "\5a"; }
.icon-bed:before { content: "\30"; }
.icon-beer:before { content: "\31"; }
.icon-behance:before { content: "\32"; }
.icon-behance-square:before { content: "\33"; }
.icon-bell:before { content: "\34"; }
.icon-bell-o:before { content: "\35"; }
.icon-bell-slash:before { content: "\36"; }
.icon-bell-slash-o:before { content: "\37"; }
.icon-bicycle:before { content: "\38"; }
.icon-binoculars:before { content: "\39"; }
.icon-birthday-cake:before { content: "\21"; }
.icon-bitbucket:before { content: "\22"; }
.icon-bitbucket-square:before { content: "\23"; }
.icon-black-tie:before { content: "\24"; }
.icon-bold:before { content: "\25"; }
.icon-bolt:before { content: "\26"; }
.icon-bomb:before { content: "\27"; }
.icon-book:before { content: "\28"; }
.icon-bookmark:before { content: "\29"; }
.icon-bookmark-o:before { content: "\2a"; }
.icon-briefcase:before { content: "\2b"; }
.icon-btc:before { content: "\2c"; }
.icon-bug:before { content: "\2d"; }
.icon-building:before { content: "\2e"; }
.icon-building-o:before { content: "\2f"; }
.icon-bullhorn:before { content: "\3a"; }
.icon-bullseye:before { content: "\3b"; }
.icon-bus:before { content: "\3c"; }
.icon-buysellads:before { content: "\3d"; }
.icon-calculator:before { content: "\3e"; }
.icon-calendar:before { content: "\3f"; }
.icon-calendar-check-o:before { content: "\40"; }
.icon-calendar-minus-o:before { content: "\5b"; }
.icon-calendar-o:before { content: "\5d"; }
.icon-calendar-plus-o:before { content: "\5e"; }
.icon-calendar-times-o:before { content: "\5f"; }
.icon-camera:before { content: "\60"; }
.icon-camera-retro:before { content: "\7b"; }
.icon-car:before { content: "\7c"; }
.icon-caret-down:before { content: "\7d"; }
.icon-caret-left:before { content: "\7e"; }
.icon-caret-right:before { content: "\5c"; }
.icon-caret-square-o-down:before { content: "\e000"; }
.icon-caret-square-o-left:before { content: "\e001"; }
.icon-caret-square-o-right:before { content: "\e002"; }
.icon-caret-square-o-up:before { content: "\e003"; }
.icon-caret-up:before { content: "\e004"; }
.icon-cart-arrow-down:before { content: "\e005"; }
.icon-cart-plus:before { content: "\e006"; }
.icon-cc:before { content: "\e007"; }
.icon-cc-amex:before { content: "\e008"; }
.icon-cc-diners-club:before { content: "\e009"; }
.icon-cc-discover:before { content: "\e00a"; }
.icon-cc-jcb:before { content: "\e00b"; }
.icon-cc-mastercard:before { content: "\e00c"; }
.icon-cc-paypal:before { content: "\e00d"; }
.icon-cc-stripe:before { content: "\e00e"; }
.icon-cc-visa:before { content: "\e00f"; }
.icon-certificate:before { content: "\e010"; }
.icon-chain-broken:before { content: "\e011"; }
.icon-check:before { content: "\e012"; }
.icon-check-circle:before { content: "\e013"; }
.icon-check-circle-o:before { content: "\e014"; }
.icon-check-square:before { content: "\e015"; }
.icon-check-square-o:before { content: "\e016"; }
.icon-chevron-circle-down:before { content: "\e017"; }
.icon-chevron-circle-left:before { content: "\e018"; }
.icon-chevron-circle-right:before { content: "\e019"; }
.icon-chevron-circle-up:before { content: "\e01a"; }
.icon-chevron-down:before { content: "\e01b"; }
.icon-chevron-left:before { content: "\e01c"; }
.icon-chevron-right:before { content: "\e01d"; }
.icon-chevron-up:before { content: "\e01e"; }
.icon-child:before { content: "\e01f"; }
.icon-chrome:before { content: "\e020"; }
.icon-circle:before { content: "\e021"; }
.icon-circle-o:before { content: "\e022"; }
.icon-circle-o-notch:before { content: "\e023"; }
.icon-circle-thin:before { content: "\e024"; }
.icon-clipboard:before { content: "\e025"; }
.icon-clock-o:before { content: "\e026"; }
.icon-clone:before { content: "\e027"; }
.icon-cloud:before { content: "\e028"; }
.icon-cloud-download:before { content: "\e029"; }
.icon-cloud-upload:before { content: "\e02a"; }
.icon-code:before { content: "\e02b"; }
.icon-code-fork:before { content: "\e02c"; }
.icon-codepen:before { content: "\e02d"; }
.icon-coffee:before { content: "\e02e"; }
.icon-cog:before { content: "\e02f"; }
.icon-cogs:before { content: "\e030"; }
.icon-columns:before { content: "\e031"; }
.icon-comment:before { content: "\e032"; }
.icon-comment-o:before { content: "\e033"; }
.icon-commenting:before { content: "\e034"; }
.icon-commenting-o:before { content: "\e035"; }
.icon-comments:before { content: "\e036"; }
.icon-comments-o:before { content: "\e037"; }
.icon-compass:before { content: "\e038"; }
.icon-compress:before { content: "\e039"; }
.icon-connectdevelop:before { content: "\e03a"; }
.icon-contao:before { content: "\e03b"; }
.icon-copyright:before { content: "\e03c"; }
.icon-creative-commons:before { content: "\e03d"; }
.icon-credit-card:before { content: "\e03e"; }
.icon-crop:before { content: "\e03f"; }
.icon-crosshairs:before { content: "\e040"; }
.icon-css3:before { content: "\e041"; }
.icon-cube:before { content: "\e042"; }
.icon-cubes:before { content: "\e043"; }
.icon-cutlery:before { content: "\e044"; }
.icon-dashcube:before { content: "\e045"; }
.icon-database:before { content: "\e046"; }
.icon-delicious:before { content: "\e047"; }
.icon-desktop:before { content: "\e048"; }
.icon-deviantart:before { content: "\e049"; }
.icon-diamond:before { content: "\e04a"; }
.icon-digg:before { content: "\e04b"; }
.icon-dot-circle-o:before { content: "\e04c"; }
.icon-download:before { content: "\e04d"; }
.icon-dribbble:before { content: "\e04e"; }
.icon-dropbox:before { content: "\e04f"; }
.icon-drupal:before { content: "\e050"; }
.icon-eject:before { content: "\e051"; }
.icon-ellipsis-h:before { content: "\e052"; }
.icon-ellipsis-v:before { content: "\e053"; }
.icon-empire:before { content: "\e054"; }
.icon-envelope:before { content: "\e055"; }
.icon-envelope-o:before { content: "\e056"; }
.icon-envelope-square:before { content: "\e057"; }
.icon-eraser:before { content: "\e058"; }
.icon-eur:before { content: "\e059"; }
.icon-exchange:before { content: "\e05a"; }
.icon-exclamation:before { content: "\e05b"; }
.icon-exclamation-circle:before { content: "\e05c"; }
.icon-exclamation-triangle:before { content: "\e05d"; }
.icon-expand:before { content: "\e05e"; }
.icon-expeditedssl:before { content: "\e05f"; }
.icon-external-link:before { content: "\e060"; }
.icon-external-link-square:before { content: "\e061"; }
.icon-eye:before { content: "\e062"; }
.icon-eye-slash:before { content: "\e063"; }
.icon-eyedropper:before { content: "\e064"; }
.icon-facebook:before { content: "\e065"; }
.icon-facebook-official:before { content: "\e066"; }
.icon-facebook-square:before { content: "\e067"; }
.icon-fast-backward:before { content: "\e068"; }
.icon-fast-forward:before { content: "\e069"; }
.icon-fax:before { content: "\e06a"; }
.icon-female:before { content: "\e06b"; }
.icon-fighter-jet:before { content: "\e06c"; }
.icon-file:before { content: "\e06d"; }
.icon-file-archive-o:before { content: "\e06e"; }
.icon-file-audio-o:before { content: "\e06f"; }
.icon-file-code-o:before { content: "\e070"; }
.icon-file-excel-o:before { content: "\e071"; }
.icon-file-image-o:before { content: "\e072"; }
.icon-file-o:before { content: "\e073"; }
.icon-file-pdf-o:before { content: "\e074"; }
.icon-file-powerpoint-o:before { content: "\e075"; }
.icon-file-text:before { content: "\e076"; }
.icon-file-text-o:before { content: "\e077"; }
.icon-file-video-o:before { content: "\e078"; }
.icon-file-word-o:before { content: "\e079"; }
.icon-files-o:before { content: "\e07a"; }
.icon-film:before { content: "\e07b"; }
.icon-filter:before { content: "\e07c"; }
.icon-fire:before { content: "\e07d"; }
.icon-fire-extinguisher:before { content: "\e07e"; }
.icon-firefox:before { content: "\e07f"; }
.icon-flag:before { content: "\e080"; }
.icon-flag-checkered:before { content: "\e081"; }
.icon-flag-o:before { content: "\e082"; }
.icon-flask:before { content: "\e083"; }
.icon-flickr:before { content: "\e084"; }
.icon-floppy-o:before { content: "\e085"; }
.icon-folder:before { content: "\e086"; }
.icon-folder-o:before { content: "\e087"; }
.icon-folder-open:before { content: "\e088"; }
.icon-folder-open-o:before { content: "\e089"; }
.icon-font:before { content: "\e08a"; }
.icon-fonticons:before { content: "\e08b"; }
.icon-forumbee:before { content: "\e08c"; }
.icon-forward:before { content: "\e08d"; }
.icon-foursquare:before { content: "\e08e"; }
.icon-frown-o:before { content: "\e08f"; }
.icon-futbol-o:before { content: "\e090"; }
.icon-gamepad:before { content: "\e091"; }
.icon-gavel:before { content: "\e092"; }
.icon-gbp:before { content: "\e093"; }
.icon-genderless:before { content: "\e094"; }
.icon-get-pocket:before { content: "\e095"; }
.icon-gg:before { content: "\e096"; }
.icon-gg-circle:before { content: "\e097"; }
.icon-gift:before { content: "\e098"; }
.icon-git:before { content: "\e099"; }
.icon-git-square:before { content: "\e09a"; }
.icon-github:before { content: "\e09b"; }
.icon-github-alt:before { content: "\e09c"; }
.icon-github-square:before { content: "\e09d"; }
.icon-glass:before { content: "\e09e"; }
.icon-globe:before { content: "\e09f"; }
.icon-google:before { content: "\e0a0"; }
.icon-google-plus:before { content: "\e0a1"; }
.icon-google-plus-square:before { content: "\e0a2"; }
.icon-google-wallet:before { content: "\e0a3"; }
.icon-graduation-cap:before { content: "\e0a4"; }
.icon-gratipay:before { content: "\e0a5"; }
.icon-h-square:before { content: "\e0a6"; }
.icon-hacker-news:before { content: "\e0a7"; }
.icon-hand-lizard-o:before { content: "\e0a8"; }
.icon-hand-o-down:before { content: "\e0a9"; }
.icon-hand-o-left:before { content: "\e0aa"; }
.icon-hand-o-right:before { content: "\e0ab"; }
.icon-hand-o-up:before { content: "\e0ac"; }
.icon-hand-paper-o:before { content: "\e0ad"; }
.icon-hand-peace-o:before { content: "\e0ae"; }
.icon-hand-pointer-o:before { content: "\e0af"; }
.icon-hand-rock-o:before { content: "\e0b0"; }
.icon-hand-scissors-o:before { content: "\e0b1"; }
.icon-hand-spock-o:before { content: "\e0b2"; }
.icon-hdd-o:before { content: "\e0b3"; }
.icon-header:before { content: "\e0b4"; }
.icon-headphones:before { content: "\e0b5"; }
.icon-heart:before { content: "\e0b6"; }
.icon-heart-o:before { content: "\e0b7"; }
.icon-heartbeat:before { content: "\e0b8"; }
.icon-history:before { content: "\e0b9"; }
.icon-home:before { content: "\e0ba"; }
.icon-hospital-o:before { content: "\e0bb"; }
.icon-hourglass:before { content: "\e0bc"; }
.icon-hourglass-end:before { content: "\e0bd"; }
.icon-hourglass-half:before { content: "\e0be"; }
.icon-hourglass-o:before { content: "\e0bf"; }
.icon-hourglass-start:before { content: "\e0c0"; }
.icon-houzz:before { content: "\e0c1"; }
.icon-html5:before { content: "\e0c2"; }
.icon-i-cursor:before { content: "\e0c3"; }
.icon-ils:before { content: "\e0c4"; }
.icon-inbox:before { content: "\e0c5"; }
.icon-indent:before { content: "\e0c6"; }
.icon-industry:before { content: "\e0c7"; }
.icon-info:before { content: "\e0c8"; }
.icon-info-circle:before { content: "\e0c9"; }
.icon-inr:before { content: "\e0ca"; }
.icon-instagram:before { content: "\e0cb"; }
.icon-internet-explorer:before { content: "\e0cc"; }
.icon-ioxhost:before { content: "\e0cd"; }
.icon-italic:before { content: "\e0ce"; }
.icon-joomla:before { content: "\e0cf"; }
.icon-jpy:before { content: "\e0d0"; }
.icon-jsfiddle:before { content: "\e0d1"; }
.icon-key:before { content: "\e0d2"; }
.icon-keyboard-o:before { content: "\e0d3"; }
.icon-krw:before { content: "\e0d4"; }
.icon-language:before { content: "\e0d5"; }
.icon-laptop:before { content: "\e0d6"; }
.icon-lastfm:before { content: "\e0d7"; }
.icon-lastfm-square:before { content: "\e0d8"; }
.icon-leaf:before { content: "\e0d9"; }
.icon-leanpub:before { content: "\e0da"; }
.icon-lemon-o:before { content: "\e0db"; }
.icon-level-down:before { content: "\e0dc"; }
.icon-level-up:before { content: "\e0dd"; }
.icon-life-ring:before { content: "\e0de"; }
.icon-lightbulb-o:before { content: "\e0df"; }
.icon-line-chart:before { content: "\e0e0"; }
.icon-link:before { content: "\e0e1"; }
.icon-linkedin:before { content: "\e0e2"; }
.icon-linkedin-square:before { content: "\e0e3"; }
.icon-linux:before { content: "\e0e4"; }
.icon-list:before { content: "\e0e5"; }
.icon-list-alt:before { content: "\e0e6"; }
.icon-list-ol:before { content: "\e0e7"; }
.icon-list-ul:before { content: "\e0e8"; }
.icon-location-arrow:before { content: "\e0e9"; }
.icon-lock:before { content: "\e0ea"; }
.icon-long-arrow-down:before { content: "\e0eb"; }
.icon-long-arrow-left:before { content: "\e0ec"; }
.icon-long-arrow-right:before { content: "\e0ed"; }
.icon-long-arrow-up:before { content: "\e0ee"; }
.icon-magic:before { content: "\e0ef"; }
.icon-magnet:before { content: "\e0f0"; }
.icon-male:before { content: "\e0f1"; }
.icon-map:before { content: "\e0f2"; }
.icon-map-marker:before { content: "\e0f3"; }
.icon-map-o:before { content: "\e0f4"; }
.icon-map-pin:before { content: "\e0f5"; }
.icon-map-signs:before { content: "\e0f6"; }
.icon-mars:before { content: "\e0f7"; }
.icon-mars-double:before { content: "\e0f8"; }
.icon-mars-stroke:before { content: "\e0f9"; }
.icon-mars-stroke-h:before { content: "\e0fa"; }
.icon-mars-stroke-v:before { content: "\e0fb"; }
.icon-maxcdn:before { content: "\e0fc"; }
.icon-meanpath:before { content: "\e0fd"; }
.icon-medium:before { content: "\e0fe"; }
.icon-medkit:before { content: "\e0ff"; }
.icon-meh-o:before { content: "\e100"; }
.icon-mercury:before { content: "\e101"; }
.icon-microphone:before { content: "\e102"; }
.icon-microphone-slash:before { content: "\e103"; }
.icon-minus:before { content: "\e104"; }
.icon-minus-circle:before { content: "\e105"; }
.icon-minus-square:before { content: "\e106"; }
.icon-minus-square-o:before { content: "\e107"; }
.icon-mobile:before { content: "\e108"; }
.icon-money:before { content: "\e109"; }
.icon-moon-o:before { content: "\e10a"; }
.icon-motorcycle:before { content: "\e10b"; }
.icon-mouse-pointer:before { content: "\e10c"; }
.icon-music:before { content: "\e10d"; }
.icon-neuter:before { content: "\e10e"; }
.icon-newspaper-o:before { content: "\e10f"; }
.icon-object-group:before { content: "\e110"; }
.icon-object-ungroup:before { content: "\e111"; }
.icon-odnoklassniki:before { content: "\e112"; }
.icon-odnoklassniki-square:before { content: "\e113"; }
.icon-opencart:before { content: "\e114"; }
.icon-openid:before { content: "\e115"; }
.icon-opera:before { content: "\e116"; }
.icon-optin-monster:before { content: "\e117"; }
.icon-outdent:before { content: "\e118"; }
.icon-pagelines:before { content: "\e119"; }
.icon-paint-brush:before { content: "\e11a"; }
.icon-paper-plane:before { content: "\e11b"; }
.icon-paper-plane-o:before { content: "\e11c"; }
.icon-paperclip:before { content: "\e11d"; }
.icon-paragraph:before { content: "\e11e"; }
.icon-pause:before { content: "\e11f"; }
.icon-paw:before { content: "\e120"; }
.icon-paypal:before { content: "\e121"; }
.icon-pencil:before { content: "\e122"; }
.icon-pencil-square:before { content: "\e123"; }
.icon-pencil-square-o:before { content: "\e124"; }
.icon-phone:before { content: "\e125"; }
.icon-phone-square:before { content: "\e126"; }
.icon-picture-o:before { content: "\e127"; }
.icon-pie-chart:before { content: "\e128"; }
.icon-pied-piper:before { content: "\e129"; }
.icon-pied-piper-alt:before { content: "\e12a"; }
.icon-pinterest:before { content: "\e12b"; }
.icon-pinterest-p:before { content: "\e12c"; }
.icon-pinterest-square:before { content: "\e12d"; }
.icon-plane:before { content: "\e12e"; }
.icon-play:before { content: "\e12f"; }
.icon-play-circle:before { content: "\e130"; }
.icon-play-circle-o:before { content: "\e131"; }
.icon-plug:before { content: "\e132"; }
.icon-plus:before { content: "\e133"; }
.icon-plus-circle:before { content: "\e134"; }
.icon-plus-square:before { content: "\e135"; }
.icon-plus-square-o:before { content: "\e136"; }
.icon-power-off:before { content: "\e137"; }
.icon-print:before { content: "\e138"; }
.icon-puzzle-piece:before { content: "\e139"; }
.icon-qq:before { content: "\e13a"; }
.icon-qrcode:before { content: "\e13b"; }
.icon-question:before { content: "\e13c"; }
.icon-question-circle:before { content: "\e13d"; }
.icon-quote-left:before { content: "\e13e"; }
.icon-quote-right:before { content: "\e13f"; }
.icon-random:before { content: "\e140"; }
.icon-rebel:before { content: "\e141"; }
.icon-recycle:before { content: "\e142"; }
.icon-reddit:before { content: "\e143"; }
.icon-reddit-square:before { content: "\e144"; }
.icon-refresh:before { content: "\e145"; }
.icon-registered:before { content: "\e146"; }
.icon-renren:before { content: "\e147"; }
.icon-repeat:before { content: "\e148"; }
.icon-reply:before { content: "\e149"; }
.icon-reply-all:before { content: "\e14a"; }
.icon-retweet:before { content: "\e14b"; }
.icon-road:before { content: "\e14c"; }
.icon-rocket:before { content: "\e14d"; }
.icon-rss:before { content: "\e14e"; }
.icon-rss-square:before { content: "\e14f"; }
.icon-rub:before { content: "\e150"; }
.icon-safari:before { content: "\e151"; }
.icon-scissors:before { content: "\e152"; }
.icon-search:before { content: "\e153"; }
.icon-search-minus:before { content: "\e154"; }
.icon-search-plus:before { content: "\e155"; }
.icon-sellsy:before { content: "\e156"; }
.icon-server:before { content: "\e157"; }
.icon-share:before { content: "\e158"; }
.icon-share-alt:before { content: "\e159"; }
.icon-share-alt-square:before { content: "\e15a"; }
.icon-share-square:before { content: "\e15b"; }
.icon-share-square-o:before { content: "\e15c"; }
.icon-shield:before { content: "\e15d"; }
.icon-ship:before { content: "\e15e"; }
.icon-shirtsinbulk:before { content: "\e15f"; }
.icon-shopping-cart:before { content: "\e160"; }
.icon-sign-in:before { content: "\e161"; }
.icon-sign-out:before { content: "\e162"; }
.icon-signal:before { content: "\e163"; }
.icon-simplybuilt:before { content: "\e164"; }
.icon-sitemap:before { content: "\e165"; }
.icon-skyatlas:before { content: "\e166"; }
.icon-skype:before { content: "\e167"; }
.icon-slack:before { content: "\e168"; }
.icon-sliders:before { content: "\e169"; }
.icon-slideshare:before { content: "\e16a"; }
.icon-smile-o:before { content: "\e16b"; }
.icon-sort:before { content: "\e16c"; }
.icon-sort-alpha-asc:before { content: "\e16d"; }
.icon-sort-alpha-desc:before { content: "\e16e"; }
.icon-sort-amount-asc:before { content: "\e16f"; }
.icon-sort-amount-desc:before { content: "\e170"; }
.icon-sort-asc:before { content: "\e171"; }
.icon-sort-desc:before { content: "\e172"; }
.icon-sort-numeric-asc:before { content: "\e173"; }
.icon-sort-numeric-desc:before { content: "\e174"; }
.icon-soundcloud:before { content: "\e175"; }
.icon-space-shuttle:before { content: "\e176"; }
.icon-spinner:before { content: "\e177"; }
.icon-spoon:before { content: "\e178"; }
.icon-spotify:before { content: "\e179"; }
.icon-square:before { content: "\e17a"; }
.icon-square-o:before { content: "\e17b"; }
.icon-stack-exchange:before { content: "\e17c"; }
.icon-stack-overflow:before { content: "\e17d"; }
.icon-star:before { content: "\e17e"; }
.icon-star-half:before { content: "\e17f"; }
.icon-star-half-o:before { content: "\e180"; }
.icon-star-o:before { content: "\e181"; }
.icon-steam:before { content: "\e182"; }
.icon-steam-square:before { content: "\e183"; }
.icon-step-backward:before { content: "\e184"; }
.icon-step-forward:before { content: "\e185"; }
.icon-stethoscope:before { content: "\e186"; }
.icon-sticky-note:before { content: "\e187"; }
.icon-sticky-note-o:before { content: "\e188"; }
.icon-stop:before { content: "\e189"; }
.icon-street-view:before { content: "\e18a"; }
.icon-strikethrough:before { content: "\e18b"; }
.icon-stumbleupon:before { content: "\e18c"; }
.icon-stumbleupon-circle:before { content: "\e18d"; }
.icon-subscript:before { content: "\e18e"; }
.icon-subway:before { content: "\e18f"; }
.icon-suitcase:before { content: "\e190"; }
.icon-sun-o:before { content: "\e191"; }
.icon-superscript:before { content: "\e192"; }
.icon-table:before { content: "\e193"; }
.icon-tablet:before { content: "\e194"; }
.icon-tachometer:before { content: "\e195"; }
.icon-tag:before { content: "\e196"; }
.icon-tags:before { content: "\e197"; }
.icon-tasks:before { content: "\e198"; }
.icon-taxi:before { content: "\e199"; }
.icon-television:before { content: "\e19a"; }
.icon-tencent-weibo:before { content: "\e19b"; }
.icon-terminal:before { content: "\e19c"; }
.icon-text-height:before { content: "\e19d"; }
.icon-text-width:before { content: "\e19e"; }
.icon-th:before { content: "\e19f"; }
.icon-th-large:before { content: "\e1a0"; }
.icon-th-list:before { content: "\e1a1"; }
.icon-thumb-tack:before { content: "\e1a2"; }
.icon-thumbs-down:before { content: "\e1a3"; }
.icon-thumbs-o-down:before { content: "\e1a4"; }
.icon-thumbs-o-up:before { content: "\e1a5"; }
.icon-thumbs-up:before { content: "\e1a6"; }
.icon-ticket:before { content: "\e1a7"; }
.icon-times:before { content: "\e1a8"; }
.icon-times-circle:before { content: "\e1a9"; }
.icon-times-circle-o:before { content: "\e1aa"; }
.icon-tint:before { content: "\e1ab"; }
.icon-toggle-off:before { content: "\e1ac"; }
.icon-toggle-on:before { content: "\e1ad"; }
.icon-trademark:before { content: "\e1ae"; }
.icon-train:before { content: "\e1af"; }
.icon-transgender:before { content: "\e1b0"; }
.icon-transgender-alt:before { content: "\e1b1"; }
.icon-trash:before { content: "\e1b2"; }
.icon-trash-o:before { content: "\e1b3"; }
.icon-tree:before { content: "\e1b4"; }
.icon-trello:before { content: "\e1b5"; }
.icon-tripadvisor:before { content: "\e1b6"; }
.icon-trophy:before { content: "\e1b7"; }
.icon-truck:before { content: "\e1b8"; }
.icon-try:before { content: "\e1b9"; }
.icon-tty:before { content: "\e1ba"; }
.icon-tumblr:before { content: "\e1bb"; }
.icon-tumblr-square:before { content: "\e1bc"; }
.icon-twitch:before { content: "\e1bd"; }
.icon-twitter:before { content: "\e1be"; }
.icon-twitter-square:before { content: "\e1bf"; }
.icon-umbrella:before { content: "\e1c0"; }
.icon-underline:before { content: "\e1c1"; }
.icon-undo:before { content: "\e1c2"; }
.icon-university:before { content: "\e1c3"; }
.icon-unlock:before { content: "\e1c4"; }
.icon-unlock-alt:before { content: "\e1c5"; }
.icon-upload:before { content: "\e1c6"; }
.icon-usd:before { content: "\e1c7"; }
.icon-user:before { content: "\e1c8"; }
.icon-user-md:before { content: "\e1c9"; }
.icon-user-plus:before { content: "\e1ca"; }
.icon-user-secret:before { content: "\e1cb"; }
.icon-user-times:before { content: "\e1cc"; }
.icon-users:before { content: "\e1cd"; }
.icon-venus:before { content: "\e1ce"; }
.icon-venus-double:before { content: "\e1cf"; }
.icon-venus-mars:before { content: "\e1d0"; }
.icon-viacoin:before { content: "\e1d1"; }
.icon-video-camera:before { content: "\e1d2"; }
.icon-vimeo:before { content: "\e1d3"; }
.icon-vimeo-square:before { content: "\e1d4"; }
.icon-vine:before { content: "\e1d5"; }
.icon-vk:before { content: "\e1d6"; }
.icon-volume-down:before { content: "\e1d7"; }
.icon-volume-off:before { content: "\e1d8"; }
.icon-volume-up:before { content: "\e1d9"; }
.icon-weibo:before { content: "\e1da"; }
.icon-weixin:before { content: "\e1db"; }
.icon-whatsapp:before { content: "\e1dc"; }
.icon-wheelchair:before { content: "\e1dd"; }
.icon-wifi:before { content: "\e1de"; }
.icon-wikipedia-w:before { content: "\e1df"; }
.icon-windows:before { content: "\e1e0"; }
.icon-wordpress:before { content: "\e1e1"; }
.icon-wrench:before { content: "\e1e2"; }
.icon-xing:before { content: "\e1e3"; }
.icon-xing-square:before { content: "\e1e4"; }
.icon-y-combinator:before { content: "\e1e5"; }
.icon-yahoo:before { content: "\e1e6"; }
.icon-yelp:before { content: "\e1e7"; }
.icon-youtube:before { content: "\e1e8"; }
.icon-youtube-play:before { content: "\e1e9"; }
.icon-youtube-square:before { content: "\e1ea"; }
.icon-zap:before { content: "\62"; }