.switch label input[type=checkbox] {
	&:checked + .lever {
		background-color: $switch-checked-lever-bg;
		&:after {
			background-color: $switch-bg-color;
		}
	}
}
.switch label .lever {
	height: 3px;
	background-color: $switch-unchecked-lever-bg;
	border-radius: $switch-radius;
	margin: 6px 16px;
	&:after {
		background-color: $switch-unchecked-bg;
		border:2px solid $switch-bg-color;
		box-shadow: none;
		top:-9px;
	}
}
input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px transparentize($switch-bg-color, .9);
}
input[type=checkbox]:not(:disabled) ~ .lever:active:after,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(0, 0, 0, .08);
}
.switch label input[type=checkbox][disabled] + .lever{
	background-color: $disableColor;
}
.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
	background-color: $disableColor;
	border:2px solid $disableColor;
}
