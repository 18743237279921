/* Select Field
   ========================================================================== */
select {
	background-color: $select-background;
	padding: $select-padding;
	border: $select-border;
	border-radius: $select-radius;
	height: $input-height;
}
.select-wrapper {
	input.select-dropdown {
		border-bottom: $input-border;
		height: $input-height;
		line-height: $input-height;
		font-size: $input-font-size;
		margin: $input-margin;
	}
	span.caret {
		color: color("hatch-blue", "base");
		&.disabled {
			color: $input-disabled-color;
		}
	}
	& + label {
		top: -22px;
		font-size: 12px;
	}
}

// Disabled styles
select:disabled {
	color: rgba(0, 0, 0, .3);
}

.select-wrapper input.select-dropdown:disabled {
	color: rgba(0, 0, 0, .3);
	border-bottom: 1px solid rgba(0, 0, 0, .3);
}

.select-wrapper i {
	color: $select-disabled-color;
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
	color: $select-disabled-color;
	background-color: transparent;
}

// Icons
.select-dropdown li {
	img {
		height: $dropdown-item-height - 10;
		width: $dropdown-item-height - 10;
	}
}

// Optgroup styles
.select-dropdown li.optgroup {
	border-top: 1px solid $dropdown-hover-bg-color;

	&.selected > span {
		color: rgba(0, 0, 0, .7);
	}

	& > span {
		color: rgba(0, 0, 0, .4);
	}

	& ~ li.optgroup-option {
		padding-left: 1rem;
	}
}