.waves-effect {
	// Waves Colors
	&.waves-light .waves-ripple {
		background-color: rgba(255, 255, 255, 0.45);
	}
	&.waves-hatch-yellow .waves-ripple {
		background-color: rgba(225, 224, 0, .70);
	}
	&.waves-hatch-orange .waves-ripple {
		background-color: rgba(232, 74, 55, .70);
	}
	&.waves-hatch-blue .waves-ripple {
		background-color: rgba(66, 85, 99, 0.70);
	}
	&.waves-hatch-goblin .waves-ripple {
		background-color: rgba(50, 110, 66, 0.70);
	}
	&.waves-hatch-grape .waves-ripple {
		background-color: rgba(66, 35, 93, 0.70);
	}
	&.waves-hatch-black .waves-ripple {
		background-color: rgba(35, 31, 32, 0.70);
	}

	&.waves-hatch .waves-ripple {
		background-color: rgba(66, 85, 99, 0.1);
	}
}