.dropdown-content {
	background-color: $dropdown-bg-color;
	padding: 8px 0;
	li {
		color: $off-black;
		min-height: $dropdown-item-height;
		line-height: $dropdown-item-height;
		&:hover, &.active, &.selected {
			background-color: $dropdown-hover-bg-color;
		}
		&.active.selected {
			background-color: darken($dropdown-hover-bg-color, 5%);
		}
		& > a, & > span {
			font-size: $globalTextSize;
			color: $dropdown-color;
			line-height: 22px;
			padding: (($dropdown-item-height - 22) / 2) 16px;
		}
	}
}

