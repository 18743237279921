[type="checkbox"] {
	+ label:before,
	&:not(.filled-in) + label:after {
		border: 2px solid $radio-empty-color;
	}
	&:not(:checked):disabled + label:before {
		background-color: $input-disabled-color;
	}
	&.tabbed:focus + label:after {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
		background-color: rgba(0, 0, 0, .1);
	}
}
[type="checkbox"]:checked {
	+ label:before {
		border-right: $radio-border;
		border-bottom: $radio-border;
	}
	&:disabled + label:before {
		border-right: 2px solid $input-disabled-color;
		border-bottom: 2px solid $input-disabled-color;
	}
}
[type="checkbox"]:indeterminate {
	+ label:before {
		border-right: $radio-border;
	}
	&:disabled + label:before {
		border-right: 2px solid $input-disabled-color;
	}
}
[type="checkbox"].filled-in {
	&:not(:checked) + label:after {
		border: 2px solid $radio-empty-color;
	}
	&:checked {
		+ label:before {
			border-right: 2px solid $input-background;
			border-bottom: 2px solid $input-background;
		}
		+ label:after {
			border: 2px solid $radio-fill-color;
			background-color: $radio-fill-color;
		}
	}
	&.tabbed:focus + label:after {
		border-color: $radio-empty-color;
	}
	&.tabbed:checked:focus + label:after {
		background-color: $secondary-color;
		border-color: $secondary-color;
	}
	&:disabled:not(:checked) + label:after {
		background-color: $input-disabled-solid-color;
	}
	&:disabled:checked + label:after {
		background-color: $input-disabled-solid-color;
		border-color: $input-disabled-solid-color;
	}
}