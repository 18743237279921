.slider {
	position: relative;
	height: 400px;
	width: 100%;

	// Fullscreen slider
	&.fullscreen {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		ul.slides {
			height: 100%;
		}

		ul.indicators {
			z-index: 2;
			bottom: 30px;
		}
	}

	.slides {
		background-color: $slider-bg-color;
		margin: 0;
		height: 400px;

		li {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: inherit;
			overflow: hidden;

			img {
				height: 100%;
				width: 100%;
				background-size: cover;
				background-position: center;
			}

			.caption {
				color: #fff;
				position: absolute;
				top: 15%;
				left: 15%;
				width: 70%;
				opacity: 0;
				h3{
					color: #fff;
				}
				p {
					color: $slider-bg-color-light;
				}
			}

			&.active {
				z-index: 2;
			}
		}
	}

	.indicators {
		position: absolute;
		text-align: center;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0;

		.indicator-item {
			display: inline-block;
			position: relative;
			cursor: pointer;
			height: 16px;
			width: 16px;
			margin: 0 12px;
			background-color: $slider-bg-color-light;

			transition: background-color .3s;
			border-radius: 50%;

			&.active {
				background-color: $slider-indicator-color;
			}
		}
	}

}