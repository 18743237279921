.chip {
	color: $globalTextColor;
	background-color: $chip-bg-color;
	margin-bottom: $chip-margin;
	margin-right: $chip-margin;
}
.chips {
	border-bottom: 1px solid $chip-border-color;
	margin: $input-margin;
	&.focus {
		border-bottom: 1px solid $chip-selected-color;
		box-shadow: none;
	}
	.chip.selected {
		background-color: $chip-selected-color;
	}
	.input {
		color: rgba(0, 0, 0, .6);
		font-size: $input-font-size;
		height: $input-height;
	}
}
