nav {
	&.nav-extended {
		height: auto;

		.nav-wrapper {
			min-height: $navbar-height-mobile;
			height: auto;
		}

		.nav-content {
			position: relative;
			line-height: normal;
		}
	}

	color: $navbar-font-color;
	@extend .z-depth-1;
	background-color: $primary-color;
	width: 100%;
	height: $navbar-height-mobile;
	line-height: $navbar-height-mobile;

	a {
		color: $navbar-font-color;
	}

	i,
	[class^="mdi-"], [class*="mdi-"],
	i.material-icons {
		display: block;
		font-size: 24px;
		height: $navbar-height-mobile;
		line-height: $navbar-height-mobile;
	}

	.nav-wrapper {
		position: relative;
		height: 100%;
	}

	@media #{$large-and-up} {
		a.button-collapse {
			display: none;
		}
	}

	// Collapse button
	.button-collapse {
		float: left;
		position: relative;
		z-index: 1;
		height: $navbar-height-mobile;
		margin: 0 18px;

		i {
			height: $navbar-height-mobile;
			line-height: $navbar-height-mobile;
		}
	}

	// Logo
	.brand-logo {
		position: absolute;
		color: $navbar-font-color;
		display: inline-block;
		font-size: $navbar-brand-font-size;
		padding: 0;
		white-space: nowrap;

		&.center {
			left: 50%;
			transform: translateX(-50%);
		}

		@media #{$medium-and-down} {
			left: 50%;
			transform: translateX(-50%);

			&.left, &.right {
				padding: 0;
				transform: none;
			}

			&.left {
				left: 0.5rem;
			}
			&.right {
				right: 0.5rem;
				left: auto;
			}
		}

		&.right {
			right: 0.5rem;
			padding: 0;
		}

		i,
		[class^="mdi-"], [class*="mdi-"],
		i.material-icons {
			float: left;
			margin-right: 15px;
		}
	}

	// Title +
	.nav-title {
		padding: 28px 0 28px 32px;
		color: $globalTextColor;
	}



	// Navbar Links
	ul {
		margin: 0;

		li {
			transition: background-color .3s;
			float: left;
			padding: 0;

			&.active {
				background-color: rgba(0, 0, 0, .1);
			}
		}
		a {
			transition: background-color .3s;
			font-size: $navbar-font-size;
			color: $navbar-font-color;
			display: block;
			padding: 0 15px;
			cursor: pointer;

			&.btn, &.btn-large, &.btn-flat, &.btn-floating {
				margin-top: -2px;
				margin-left: 15px;
				margin-right: 15px;
			}

			&:hover {
				background-color: rgba(0, 0, 0, .1);
			}
		}

		&.left {
			float: left;
		}
	}

	// Navbar Search Form
	form {
		height: 100%;
	}

	.input-field {
		margin: 0;
		height: 100%;

		input {
			height: 100%;
			font-size: 1.2rem;
			border: none;
			padding-left: 2rem;

			&:focus, &[type=text]:valid, &[type=password]:valid,
			&[type=email]:valid, &[type=url]:valid, &[type=date]:valid {
				border: none;
				box-shadow: none;
			}
		}

		label {
			top: 0;
			left: 0;

			i {
				color: $globalTextColorLight;;
				transition: color .3s;
			}
			&.active i {
				color: $navbar-font-color;
			}
		}
	}
}
// Fixed Navbar
.navbar-fixed {
	position: relative;
	height: $navbar-height-mobile;
	z-index: 997;

	nav {
		position: fixed;
	}
}

@media #{$medium-and-up} {
	nav.nav-extended .nav-wrapper {
		min-height: $navbar-height;
	}
	nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
		height: $navbar-height;
		line-height: $navbar-height;
	}
	.navbar-fixed {
		height: $navbar-height;
	}
}
