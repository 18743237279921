﻿/* Icons */
@font-face {
    font-family: 'hatchfont';
    src: url('../fonts/hatchfont.eot');
    src: url('../fonts/hatchfont.eot?#iefix') format('embedded-opentype'), url('../fonts/hatchfont.woff') format('woff'), url('../fonts/hatchfont.ttf') format('truetype'), url('../fonts/hatchfont.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.hatch-icon {
    font-family: 'hatchfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    &-logo-old:before {
        content: "\e600";
    }

    &-logo:before {
        content: "\e601";
    }

    &-plus:before {
        content: "\e602";
    }

    &-positive-change:before {
        content: "\e603";
    }
}

.hatch-icon {
    display: inline-block;
    height: 16px;
    line-height: 16px;
    transform: translateY(-2px);
    overflow: hidden;
}

.hatch-icon-logo {
    // font-size: 54px; // smallest allowable is 54px
    font-size: 96px;
}

.hatch-icon-plus {
    font-size: 15px;
}

.hatch-icon-positive-change {
    font-size: 36px;
}