.card-panel {
	padding: $card-padding;
	margin: $element-top-margin 0 $element-bottom-margin 0;
	@extend .z-depth-1;
	background-color: $card-bg-color;
}

.card {
	margin: $element-top-margin 0 $element-bottom-margin 0;
	background-color: $card-bg-color;
	@extend .z-depth-1;
	.card-image {
		.card-title {
			color: $card-bg-color;
			padding: $card-padding;
		}
	}
	.card-title {
		font-weight: 400;
	}
	.card-content {
		padding: $card-padding;
	}
	.card-action {
		padding: 16px $card-padding;
		a:not(.btn):not(.btn-large):not(.btn-floating) {
			color: $card-link-color;
			margin-right: $card-padding;
			&:hover {
				color: $card-link-color-light;
			}
		}
	}
	.card-reveal {
		padding: $card-padding;
		background-color: $card-bg-color;
	}
}
