$hatch-orange: rgb(232, 74, 55);
$hatch-blue-dark: rgb(43, 55, 64);
$hatch-blue-light: rgb(88, 116, 135);
$hatch-blue-bright: rgb(206, 216, 222);
$hatch-porcelain: rgb(247, 249, 250);
$hatch-black: rgb(35, 31, 32);



$hatch-blue: (
		"lighten-8":  $hatch-porcelain,
		"lighten-7":  rgb(227, 233, 237),
		"lighten-6":  $hatch-blue-bright,
		"lighten-5":  rgb(181, 196, 207),
		"lighten-4":  rgb(156, 176, 191),
		"lighten-3":  rgb(119, 147, 166),
		"lighten-2":  $hatch-blue-light,
		"lighten-1":  rgb(72, 95, 111),
		"base":       rgb(66, 85, 99),
		"darken-1":   $hatch-blue-dark,
		"darken-2":   $hatch-black
);
$hatch-yellow: (
		"lighten-5":  rgb(250, 250, 228),
		"lighten-4":  rgb(249, 249, 204),
		"lighten-3":  rgb(243, 243, 153),
		"lighten-2":  rgb(237, 236, 102),
		"lighten-1":  rgb(231, 230, 51),
		"base":       rgb(225, 224, 0),
		"darken-1":   rgb(202, 201, 0),
		"darken-2":   rgb(180, 179, 0)
);

$hatch-goblin: (
		"lighten-4":  rgb(214, 226, 218),
		"lighten-3":  rgb(174, 198, 179),
		"lighten-2":  rgb(133, 168, 142),
		"lighten-1":  rgb(92, 139, 104),
		"base":       rgb(50, 110, 66),
);

$hatch-grape: (
		"lighten-4":  rgb(217, 212, 222),
		"lighten-3":  rgb(180, 167, 191),
		"lighten-2":  rgb(142, 124, 158),
		"lighten-1":  rgb(104, 79, 126),
		"base":       rgb(66, 35, 93)
);

$shades: (
		"hatch-orange": $hatch-orange,
		"hatch-white":  #FFFFFF,
		"transparent":  transparent
);

$colors: (
		"hatch-blue": $hatch-blue,
		"hatch-yellow": $hatch-yellow,
		"hatch-goblin": $hatch-goblin,
		"hatch-grape": $hatch-grape
);
// Color Classes

@each $color_name, $color in $colors {
	@each $color_type, $color_value in $color {
		@if $color_type == "base" {
			.#{$color_name} {
				background-color: $color_value !important;
			}
			.#{$color_name}-text {
				color: $color_value !important;
			}
		}
		@else if $color_name != "shades" {
			.#{$color_name}.#{$color_type} {
				background-color: $color_value !important;
			}
			.#{$color_name}-text.text-#{$color_type} {
				color: $color_value !important;
			}
		}
	}
}

// Shade classes
@each $color, $color_value in $shades {
	.#{$color} {
		background-color: $color_value !important;
	}
	.#{$color}-text {
		color: $color_value !important;
	}
}


// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)

@function color($color, $type) {
	@if map-has-key($colors, $color) {
		$curr_color: map-get($colors, $color);
		@if map-has-key($curr_color, $type) {
			@return map-get($curr_color, $type);
		}
	}
	@warn "Unknown `#{name}` in $colors.";
	@return null;
}

.dynamic-color {
	.hatch-orange, .hatch-blue, .hatch-yellow, .hatch-goblin, .hatch-grape, .hatch-white, .transparent-box{
		height: 55px;
		width: 100%;
		padding: 0 15px;
		line-height: 55px;
		font-weight: 500;
		font-size: 12px;
		display: block;
		box-sizing: border-box;
	}
	.transparent-box{
		overflow: hidden;
		text-indent: -9898px;
	}
	.col {
		margin-bottom: 55px;
	}
}