[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
	border: 2px solid $radio-empty-color;
}
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
	border: $radio-border;
}
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
	background-color: $radio-fill-color;
}
[type="radio"].tabbed:focus + label:before {
	box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
}
[type="radio"].with-gap:disabled:checked + label:before {
	border: 2px solid $input-disabled-color;
}
[type="radio"].with-gap:disabled:checked + label:after {
	background-color: $input-disabled-color;
}
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
	border-color: $input-disabled-color;
}
[type="radio"]:disabled + label {
	color: $input-disabled-color;
}
[type="radio"]:disabled:not(:checked) + label:before {
	border-color: $input-disabled-color;
}
[type="radio"]:disabled:checked + label:after {
	background-color: $input-disabled-color;
	border-color: $input-disabled-solid-color;
}