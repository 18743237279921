/* Text Inputs + Textarea
   ========================================================================== */

/* Style Placeholders */

::-webkit-input-placeholder {
	color: $placeholder-text-color;
}

:-moz-placeholder { /* Firefox 18- */
	color: $placeholder-text-color;
}

::-moz-placeholder { /* Firefox 19+ */
	color: $placeholder-text-color;
}

:-ms-input-placeholder {
	color: $placeholder-text-color;
}

/* Text inputs */

input:not([type]),
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search],
textarea.materialize-textarea {

	// General Styles
	background-color: transparent;
	border: none;
	border-bottom: $input-border;
	border-radius: 0;
	outline: none;
	height: $input-height;
	width: 100%;
	font-size: $input-font-size;
	margin: $input-margin;
	padding: $input-padding;
	box-shadow: none;
	box-sizing: content-box;
	transition: $input-transition;

	// Disabled input style
	&:disabled,
	&[readonly="readonly"] {
		color: $input-disabled-color;
		border-bottom: $input-disabled-border;
	}

	// Disabled label style
	&:disabled + label,
	&[readonly="readonly"] + label {
		color: $input-disabled-color;
	}

	// Focused input style
	&:focus:not([readonly]) {
		border-bottom: 1px solid $input-focus-color;
		box-shadow: 0 1px 0 0 $input-focus-color;
	}

	// Focused label style
	&:focus:not([readonly]) + label {
		color: color("hatch-blue", "lighten-3");
		opacity: 1;
	}

	// Valid Input Style
	&.valid,
	&:focus.valid {
		border-bottom: 1px solid color("hatch-goblin", "lighten-2");
		box-shadow: 0 1px 0 0 color("hatch-goblin", "lighten-2");
	}

	// Custom Success Message
	&.valid + label:after,
	&:focus.valid + label:after {
		content: attr(data-success);
		color: color("hatch-goblin", "lighten-2");
		opacity: 1;
	}

	// Invalid Input Style
	&.invalid,
	&:focus.invalid {
		border-bottom: $input-invalid-border;
		box-shadow: 0 1px 0 0 $input-error-color;
	}

	// Custom Error message
	&.invalid + label:after,
	&:focus.invalid + label:after {
		content: attr(data-error);
		color: $input-error-color;
		opacity: 1;
	}

	// Full width label when using validate for error messages
	&.validate + label {
		width: 100%;
		pointer-events: none;
	}

	// Form Message Shared Styles
	& + label:after {
		top: 64px;
	}
}

// Styling for input field wrapper
.input-field {
	margin-top: 20px;
	&.col {
		label {
			left: $gutter-width / 2;
		}
	}
	label {
		color: $globalTextColorLight;
		top: 6px;
		&:not(.label-icon).active {
			font-size: 12px;
			transform: translateY(-115%);
			color: color("hatch-blue", "lighten-3");
		}
	}
	.prefix {
		width: $input-height;
		color: $globalTextColorLight;
		&.active {
			color: $globalTextColorLight;
		}
	}
}
.input-field input[type=search] {
	&:focus {
		background-color: $input-background;
	}
}
/* Textarea */
textarea {
	height: $input-height;
	&.materialize-textarea {
		min-height: $input-height;
	}
}
/* Autocomplete */
.autocomplete-content {
	padding: 0;
	li {
		img {
			height: $dropdown-item-height - 10;
			width: $dropdown-item-height - 10;
		}
	}
}
